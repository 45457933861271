import * as React from 'react';

export const ShieldSvg = (props: any) => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M12 22C12 22 20 18 20 12V5L12 2L4 5V12C4 18 12 22 12 22Z'
				stroke='#1A4D57'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const CrossSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect width={24} height={24} rx={2} fill='black' />
			<path
				d='M17.3334 6.66663L6.66669 17.3333'
				stroke='white'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M6.66669 6.66663L17.3334 17.3333'
				stroke='white'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const DiscountIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={45}
			height={45}
			viewBox='0 0 45 45'
			{...props}
		>
			<g fill='none' fillRule='nonzero'>
				<path
					fill='#FFBB58'
					d='M22.5 0l4.607 5.143L33.75 3l1.5 6.75 6.75 1.5-2.143 6.643L45 22.5l-5.143 4.607L42 33.75l-6.75 1.5-1.5 6.75-6.643-2.143L22.5 45l-4.607-5.143L11.25 42l-1.5-6.75L3 33.75l2.143-6.643L0 22.5l5.143-4.607L3 11.25l6.75-1.5L11.25 3l6.643 2.143z'
				/>
				<g fill='#FFF'>
					<path d='M18.214 31.393l-1.821-1.072 10.393-16.714 1.821 1.072zM17.143 11.786c-1.929 0-4.286 1.178-4.286 4.285v1.072c0 1.928 1.179 4.286 4.286 4.286 2.25 0 4.286-1.5 4.286-4.286V16.07c0-2.678-1.822-4.285-4.286-4.285zm1.928 5.357c0 1.393-.75 2.143-1.928 2.143-.964 0-1.929-.643-1.929-2.143V16.07c0-1.5.965-2.25 1.929-2.25 1.5 0 1.928 1.393 1.928 2.25v1.072zM27.857 33.214c1.929 0 4.286-1.178 4.286-4.285v-1.072c0-1.928-1.179-4.286-4.286-4.286-2.25 0-4.286 1.5-4.286 4.286v1.072c0 2.678 1.822 4.285 4.286 4.285zm-1.928-5.357c0-1.393.75-2.143 1.928-2.143.964 0 1.929.643 1.929 2.143v1.072c0 1.5-.965 2.25-1.929 2.25-1.5 0-1.928-1.393-1.928-2.25v-1.072z' />
				</g>
			</g>
		</svg>
	);
};

export const WalletIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={45}
			height={40}
			viewBox='0 0 45 40'
			{...props}
		>
			<g fill='none' fillRule='nonzero'>
				<path
					fill='#4FC3F7'
					d='M0 5v30c0 2.761 1.919 5 4.286 5h35.357c1.071 0 1.071-1.245 1.071-1.245V5H0z'
				/>
				<path
					fill='#357BCC'
					d='M39.643 0H4.286C1.919 0 0 2.237 0 4.999 0 7.76 1.919 10 4.286 10h35.357c1.071 0 1.071 1.25 1.071 1.25v-10c0-.69-.479-1.25-1.071-1.25z'
				/>
				<path
					fill='#B8E986'
					d='M4.286 5v5h35.357c1.071 0 1.071 1.252 1.071 1.252V37.5h2.143V5H4.286z'
				/>
				<path
					fill='#FFF'
					d='M4.286 7.5V10h30.731a9.91 9.91 0 0 1-.643-2.5H4.286z'
				/>
				<path
					fill='#F8E71C'
					d='M45 18.75h-9.643c-2.367 0-4.286 2.239-4.286 5s1.92 5 4.286 5H45v-10z'
				/>
				<ellipse
					cx={35.357}
					cy={23.75}
					fill='#FFF'
					rx={2.143}
					ry={2.5}
				/>
			</g>
		</svg>
	);
};

export const Emoji100Svg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 16 16' {...props}>
			<g fill='none' fillRule='evenodd'>
				<mask id='emoji-100-mask' fill='#fff'>
					<path id='a' d='M0 16V0h16v16z' />
				</mask>
				<path
					fill='#ED4C5C'
					d='M2.379.342S.744 4.401.003 10.498c-.067.546 1.189.058 1.621-.107C2.604 4.387 4 .272 4 .272 3.592.203 2.38.342 2.38.342M9.408.786c.577.934.325 2.416-.024 3.885C8.807 7.045 7.449 9.5 5.252 9.5c-2.078 0-2.294-2.255-1.741-4.564.336-1.4.888-2.827 1.73-3.775C5.888.439 6.777 0 7.665 0c.865 0 1.454.172 1.742.786M5.18 4.949c-.468 1.937-.252 2.91.517 2.91.804 0 1.56-1.228 2.054-3.243.456-1.921.24-2.803-.53-2.803-.732 0-1.536 1.068-2.041 3.136M15.658.787c.576.933.324 2.415-.024 3.883-.577 2.375-1.934 4.83-4.132 4.83-2.078 0-2.294-2.255-1.742-4.564.337-1.4.889-2.827 1.73-3.775C12.14.441 13.027 0 13.916 0c.865 0 1.454.172 1.742.787M11.43 4.95c-.468 1.936-.252 2.91.516 2.91.806 0 1.562-1.229 2.054-3.243.456-1.922.24-2.804-.528-2.804-.733 0-1.538 1.068-2.042 3.137M12.15 12.421c-1.431 0-8.89.588-11.83.829-.2-.761.061-1.438.4-1.765 1.476-.278 11.29-1.036 12.03-.603-.123.536-.338 1.212-.6 1.54M10.896 15.408c-1.461 0-6.114.324-9.115.592-.204-.848.063-1.597.409-1.962 1.507-.308 8.565-.823 9.32-.343-.126.596-.346 1.347-.613 1.712'
					mask='url(#emoji-100-mask)'
				/>
			</g>
		</svg>
	);
};

export const InfoIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 14 14' {...props}>
			<path
				fill='#545454'
				fillRule='nonzero'
				d='M7 0C3.137 0 0 3.137 0 7s3.137 7 7 7 7-3.137 7-7-3.137-7-7-7zm0 .56c3.56 0 6.44 2.88 6.44 6.44 0 3.56-2.88 6.44-6.44 6.44C3.44 13.44.56 10.56.56 7 .56 3.44 3.44.56 7 .56zm-.341 3.054c-.06 0-.096.036-.096.096v.735c0 .06.036.105.096.105h.682c.06 0 .096-.045.096-.105V3.71c0-.06-.036-.096-.096-.096H6.66zM6.64 5.635a.101.101 0 0 0-.061.096v4.611c0 .06.036.097.096.097h.648c.06 0 .096-.036.096-.097v-4.61c0-.06-.036-.097-.096-.097h-.648c-.015 0-.023-.004-.035 0z'
			/>
		</svg>
	);
};

export const CurrencyTeaseSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 16 16' {...props}>
			<g fill='none' fillRule='evenodd'>
				<path fill='#FFDD67' d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0z' />
				<path
					fill='#664E27'
					d='M12.267 9.467C12.267 11.19 10.85 13.2 8 13.2c-2.85 0-4.267-2.01-4.267-3.733h8.534'
				/>
				<path
					fill='#664E27'
					d='M8 13.467c-2.944 0-4.533-2.061-4.533-4V9.2h9.066v.267c0 1.939-1.588 4-4.533 4M4.012 9.733c.14 1.598 1.527 3.2 3.988 3.2 2.461 0 3.85-1.602 3.989-3.2H4.012'
				/>
				<path
					fill='#74E063'
					d='M8 10.133H5.333v1.579C5.333 14.566 6.527 16 8 16c1.473 0 2.667-1.434 2.667-4.288v-1.578H8'
				/>
				<g transform='translate(5.6 10.133)'>
					<mask id='b' fill='#fff'>
						<path id='a' d='M.148 2.704V0h4.504v5.409H.148z' />
					</mask>
					<path
						fill='#000'
						fillOpacity={0.33}
						d='M4.438 0v1.51c0 2.225-.8 3.663-2.038 3.663C1.162 5.173.362 3.734.362 1.51V0H.148v1.51c0 2.367.883 3.898 2.251 3.899 1.368 0 2.253-1.53 2.253-3.9V0h-.214'
						mask='url(#b)'
					/>
				</g>
				<path
					fill='#000'
					fillOpacity={0.33}
					d='M5.333 11.036h5.334v-.903H5.333zM7.025 11.555c.258-.078.458-.033.601.138-.172.31-.278.536-.317.676.002.26.063.404.184.43.096.005.19-.074.28-.24l.213-.466c.147-.29.352-.442.617-.453.153.011.29.067.412.168a.712.712 0 0 1 .24.34c.039.127.058.205.059.235l.341.035a.463.463 0 0 1-.027.477l-.357-.035c-.002.145-.078.338-.23.578a.735.735 0 0 1-.563-.213c.208-.294.305-.529.29-.706.019-.165-.037-.253-.167-.263-.073-.005-.17.111-.287.35-.104.275-.225.478-.36.61a.607.607 0 0 1-.494.177.73.73 0 0 1-.603-.388c-.1-.152-.156-.269-.169-.348l-.345-.009c-.084-.243-.063-.422.063-.536l.352.03c.016-.139.105-.334.266-.587'
				/>
				<g fill='#664E27'>
					<path d='M5.467 5.54l-.624-.217c-.224-.095-.336-.203-.34-.323.02-.153.199-.244.537-.273.184.035.486.143.906.324.204-.194.242-.447.113-.76-.342-.174-.604-.264-.784-.27v-.44c-.161-.144-.394-.152-.699-.023l.025.429c-.101.024-.245.106-.43.246-.294.198-.44.46-.437.787.002.242.096.44.28.594.186.155.462.284.827.385.32.121.482.228.482.32.001.162-.106.24-.321.235-.227.035-.54-.061-.941-.288a.892.892 0 0 0-.216.72c.326.164.583.239.77.226l-.005.446c.203.105.409.1.617-.015l.008-.427c.039-.005.138-.037.297-.097a.922.922 0 0 0 .414-.333.87.87 0 0 0 .173-.528c-.044-.328-.26-.567-.652-.718M11.6 5.54l-.624-.217c-.223-.095-.336-.203-.339-.323.02-.153.198-.244.536-.273.185.035.487.143.906.324.205-.194.243-.447.114-.76-.343-.174-.605-.264-.784-.27v-.44c-.162-.144-.395-.152-.7-.023l.026.429c-.102.024-.245.106-.431.246-.294.198-.439.46-.436.787.001.242.095.44.28.594.186.155.461.284.826.385.321.121.482.228.483.32 0 .162-.106.24-.322.235-.226.035-.54-.061-.94-.288a.893.893 0 0 0-.217.72c.327.164.584.239.771.226l-.006.446c.204.105.41.1.618-.015l.008-.427c.038-.005.138-.037.296-.097a.921.921 0 0 0 .415-.333.868.868 0 0 0 .172-.528c-.043-.328-.26-.567-.65-.718' />
				</g>
			</g>
		</svg>
	);
};

export const DropdownArrowSolidSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 11 6' {...props}>
			<g
				id='Final'
				stroke='none'
				strokeWidth={1}
				fill='none'
				fillRule='evenodd'
			>
				<g
					id='ProductDetailsPage_MinorChanges-Copy'
					transform='translate(-1204.000000, -357.000000)'
					fill='#545454'
				>
					<g
						id='Group-20'
						transform='translate(948.000000, 138.000000)'
					>
						<g id='Side-Bar'>
							<g id='Module-1'>
								<g
									id='Group-2'
									transform='translate(8.015625, 23.023438)'
								>
									<g
										id='Group-4'
										transform='translate(10.984375, 173.976562)'
									>
										<g
											id='date'
											transform='translate(0.984375, 0.376562)'
										>
											<polygon
												id='Shape'
												points='236 22 241.4 27.4000001 246.8 22'
											/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export const LeftArrowSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			fill='none'
			viewBox='0 0 12 12'
			{...props}
		>
			<path
				stroke='#444'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.2'
				d='M8.5 11l-5-5 5-5'
			></path>
		</svg>
	);
};

export const RightArrowSvgThicker = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g clipPath='url(#clipRightArrow)'>
			<path
				d='M4.66666 14.6666L11.3333 7.99998L4.66666 1.33331'
				stroke='#444444'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</g>
		<defs>
			<clipPath id='clipRightArrow'>
				<rect width='16' height='16' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

export const RightArrowSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			fill='none'
			viewBox='0 0 12 12'
			{...props}
		>
			<path
				stroke='#444'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.2'
				d='M3.5 1l5 5-5 5'
			></path>
		</svg>
	);
};

export const SeatIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 12 16' {...props}>
			<g fill='none' fillRule='nonzero'>
				<path
					fill='#B4D7EE'
					d='M11.995 7.695c.053.535-.336.972-.864.972H1.535c-.527 0-.916-.438-.864-.972L1.2 2.307c.053-.535.527-.974 1.055-.974h8.156c.528 0 1.003.438 1.055.974l.53 5.388'
				/>
				<g fill='#3E4347'>
					<path d='M.215 7.457h1.477v1.981H.215zM10.124 7.457h1.477v1.981h-1.477zM1.63 12.483h1.477v2.476H1.631zM8.706 12.473h1.477v2.497H8.706z' />
				</g>
				<path
					fill='#428BC1'
					d='M8.234 6.147c-.076.652-.557 1.186-1.07 1.186H4.836c-.513 0-.994-.534-1.07-1.186L3.343 2.52c-.075-.652.282-1.187.794-1.187h3.725c.513 0 .87.535.795 1.187l-.423 3.627'
				/>
				<g fill='#94989B'>
					<path d='M3.855 14.472a.367.367 0 0 0-.324-.235H1.557a.366.366 0 0 0-.325.235l-.338 1.016c-.042.13.033.235.168.235h2.961c.136 0 .212-.105.17-.235l-.339-1.016M11.271 15.49l-.338-1.017a.368.368 0 0 0-.324-.235H8.634a.367.367 0 0 0-.325.235l-.337 1.016c-.043.13.033.235.17.235h2.96c.135 0 .212-.105.17-.235' />
				</g>
				<g fill='#29ABE2'>
					<path d='M9.415 1.487c0 .545-.42.992-.935.992H3.336c-.514 0-.936-.446-.936-.992V.992c0-.545.422-.99.936-.99H8.48c.515 0 .935.446.935.99v.495M1.85 7.183l-.074-.509c-.04-.269-.27-.489-.512-.489h-.66c-.242 0-.472.22-.512.489l-.076.509c-.04.269.127.488.368.488h1.1c.241 0 .407-.22.367-.488M11.759 7.183l-.075-.509c-.04-.269-.27-.489-.513-.489h-.66c-.24 0-.47.22-.511.489l-.076.509c-.04.269.126.488.368.488h1.1c.241 0 .407-.22.367-.488M10.703 8.871h-9.59c-.753 0-1.241.748-1.083 1.66l.11.643c.159.913.904 1.66 1.658 1.66h8.22c.753 0 1.499-.747 1.657-1.66l.11-.642c.159-.913-.329-1.66-1.082-1.66' />
				</g>
			</g>
		</svg>
	);
};

export const SupportIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 15.059 15.058' {...props}>
			<defs>
				<style>
					{
						'\n                .cls-2{fill:#4788c7}.cls-3{fill:#b6dcfe}\n            '
					}
				</style>
			</defs>
			<g id='icons-lifebuoy-support' transform='translate(0 -1)'>
				<g transform='translate(0.396 1.396)'>
					<path
						id='Shape'
						fill='#dff0fe'
						d='M6.935,13.87A6.935,6.935,0,1,1,13.87,6.935,6.943,6.943,0,0,1,6.935,13.87Zm0-10.7A3.765,3.765,0,1,0,10.7,6.935,3.769,3.769,0,0,0,6.935,3.171Z'
						transform='translate(0.198 0.198)'
						style={{
							fill: '#dff0fe',
						}}
					/>
					<path
						id='Shape-2'
						data-name='Shape'
						fill='#4788c7'
						className='cls-2'
						d='M7.133,14.266a7.133,7.133,0,1,1,7.133-7.133A7.141,7.141,0,0,1,7.133,14.266ZM7.133.4A6.737,6.737,0,1,0,13.87,7.133,6.745,6.745,0,0,0,7.133.4Zm0,10.7A3.963,3.963,0,1,1,11.1,7.133,3.967,3.967,0,0,1,7.133,11.1Zm0-7.529A3.567,3.567,0,1,0,10.7,7.133,3.571,3.571,0,0,0,7.133,3.567Z'
					/>
				</g>
				<g
					id='Group-2'
					data-name='Group'
					transform='translate(6.341 1)'
				>
					<rect
						id='Rectangle-path'
						fill='#b6dcfe'
						className='cls-3'
						width={1.981}
						height={3.963}
						transform='translate(0.198 0.198)'
					/>
					<path
						id='Shape-3'
						data-name='Shape'
						fill='#4788c7'
						className='cls-2'
						d='M2.378,4.359H0V0H2.378V4.358ZM.4.4V3.963H1.982V.4Z'
					/>
				</g>
				<g
					id='Group-3'
					data-name='Group'
					transform='translate(6.341 11.7)'
				>
					<rect
						id='Rectangle-path-2'
						data-name='Rectangle-path'
						fill='#b6dcfe'
						className='cls-3'
						width={1.981}
						height={3.963}
						transform='translate(0.198 0.198)'
					/>
					<path
						id='Shape-4'
						data-name='Shape'
						fill='#4788c7'
						className='cls-2'
						d='M2.378,4.359H0V0H2.378V4.358ZM.4.4V3.963H1.982V.4Z'
					/>
				</g>
				<g
					id='Group-4'
					data-name='Group'
					transform='translate(10.7 7.341)'
				>
					<rect
						id='Rectangle-path-3'
						data-name='Rectangle-path'
						fill='#b6dcfe'
						className='cls-3'
						width={3.963}
						height={1.981}
						transform='translate(0.198 0.198)'
					/>
					<path
						id='Shape-5'
						data-name='Shape'
						fill='#4788c7'
						className='cls-2'
						d='M4.359,2.378H0V0H4.359V2.377ZM.4.4V1.982H3.963V.4Z'
					/>
				</g>
				<g
					id='Group-5'
					data-name='Group'
					transform='translate(0 7.341)'
				>
					<rect
						id='Rectangle-path-4'
						data-name='Rectangle-path'
						fill='#b6dcfe'
						className='cls-3'
						width={3.963}
						height={1.981}
						transform='translate(0.198 0.198)'
					/>
					<path
						id='Shape-6'
						data-name='Shape'
						fill='#4788c7'
						className='cls-2'
						d='M4.359,2.378H0V0H4.359V2.377ZM.4.4V1.982H3.963V.4Z'
					/>
				</g>
			</g>
		</svg>
	);
};

export const ChevronDownSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
			<path
				d='M1.33317 4.66699L7.99984 11.3337L14.6665 4.66699'
				stroke='#444444'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const ChevronUpSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg width={16} height={8} viewBox='0 0 16 8' fill='none' {...props}>
			<path
				d='M14.6666 7.33337L7.99998 0.666707L1.33331 7.33337'
				stroke='#444444'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const PlusIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg width={15} height={15} viewBox='0 0 24 24' fill='none' {...props}>
			<path
				d='M12 19L12 5'
				stroke={props.stroke ?? '#444444'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19 12L5 12'
				stroke={props.stroke ?? '#444444'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const MinusIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg width={16} height={2} viewBox='0 0 16 2' fill='none' {...props}>
			<path
				d='M15 1L1 1'
				stroke='#444444'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const RedTickSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 33 32' fill='none' {...props}>
			<rect
				x={0.632812}
				width={32.2282}
				height={32}
				rx={16}
				fill='#EC1943'
			/>
			<path
				d='M24.3003 11.1665L13.9143 21.9998L9.19336 17.0756'
				fill='#EC1943'
			/>
			<path
				d='M24.3003 11.1665L13.9143 21.9998L9.19336 17.0756'
				stroke='white'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const RedTickBigSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 33 32' fill='none' {...props}>
			<rect
				x={0.632812}
				width={32.2282}
				height={32}
				rx={16}
				fill='#EC1943'
			/>
			<path
				d='M24.3003 11.1665L13.9143 21.9998L9.19336 17.0756'
				fill='#EC1943'
			/>
			<path
				d='M24.3003 11.1665L13.9143 21.9998L9.19336 17.0756'
				stroke='white'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const IllustrationSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 138 178' fill='none' {...props}>
			<path
				d='M38.0304 4.04392L31.2802 -5.39804e-05L30.0721 3.91369L38.0304 4.04392Z'
				fill='#EC1943'
			/>
			<circle opacity={0.4} cx={52.9999} cy={48} r={2} fill='#185332' />
			<path
				d='M133.325 38.7813L125.565 34L124.083 38.7814L133.325 38.7813Z'
				fill='#FFBB58'
			/>
			<circle
				opacity={0.4}
				r={5}
				transform='matrix(-1 0 0 1 99.9999 8)'
				fill='#185332'
			/>
			<circle
				opacity={0.4}
				r={5}
				transform='matrix(-1 0 0 1 4.99988 32)'
				fill='#FFD394'
			/>
			<g clipPath='url(#clipIllustration)'>
				<path
					d='M97.8326 59.29C93.6321 59.29 90.2269 56.6334 90.2269 53.3563C90.2269 50.0792 93.6321 47.4226 97.8326 47.4226C102.033 47.4226 105.438 50.0792 105.438 53.3563C105.438 56.6334 102.033 59.29 97.8326 59.29Z'
					fill='#3F3D56'
				/>
				<path
					d='M82.6212 84.3934C76.5724 84.3934 71.6688 79.4888 71.6688 73.4387C71.6688 67.3885 76.5724 62.4839 82.6212 62.4839C88.67 62.4839 93.5735 67.3885 93.5735 73.4387C93.5735 79.4888 88.67 84.3934 82.6212 84.3934Z'
					fill='#3F3D56'
				/>
				<path
					d='M47.8647 173.324C46.353 173.324 45.1276 172.098 45.1276 170.586C45.1276 169.074 46.353 167.849 47.8647 167.849C49.3764 167.849 50.6018 169.074 50.6018 170.586C50.6018 172.098 49.3764 173.324 47.8647 173.324Z'
					fill='#3F3D56'
				/>
				<path
					d='M62.1617 173.324C60.6501 173.324 59.4246 172.098 59.4246 170.586C59.4246 169.074 60.6501 167.849 62.1617 167.849C63.6734 167.849 64.8989 169.074 64.8989 170.586C64.8989 172.098 63.6734 173.324 62.1617 173.324Z'
					fill='#3F3D56'
				/>
				<path
					d='M57.9027 104.555H59.1199V138.18H57.9027V104.555Z'
					fill='#3F3D56'
				/>
				<path
					d='M51.3627 104.555H52.5799V138.18H51.3627V104.555Z'
					fill='#3F3D56'
				/>
				<path
					d='M58.8228 105.467L59.1276 104.555C59.1276 104.555 55.7809 100.599 51.0657 103.788L51.3705 105.158H52.5876C52.5876 105.158 54.5638 103.5 57.9104 105.394L58.8228 105.467Z'
					fill='#3F3D56'
				/>
				<path
					d='M79.2745 99.7617H90.2269V113.759H79.2745V99.7617Z'
					fill='#FBBEBE'
				/>
				<path
					d='M71.6689 95.8047H75.0155V102.499H71.6689V95.8047Z'
					fill='#FBBEBE'
				/>
				<path
					d='M88.7816 167.849L90.1502 171.957C90.1502 171.957 90.7597 175 88.7816 175.151C86.8036 175.303 83.6064 174.695 83.6064 174.695C83.6064 174.695 83.1502 173.326 83.6064 172.109C84.0626 170.891 85.4312 167.544 85.4312 167.544L88.7816 167.849Z'
					fill='#3C354C'
				/>
				<path
					d='M78.4388 168C78.4388 168 80.5683 173.326 78.895 174.239C77.2217 175.151 74.9388 174.39 73.1141 175.151C71.2893 175.912 66.2694 174.087 66.2694 174.087C66.2694 174.087 65.6598 172.413 69.0065 171.957C69.0065 171.957 72.6483 169.217 72.6483 168.61C72.6483 168.002 74.7778 168 74.7778 168H78.4388Z'
					fill='#3C354C'
				/>
				<path
					d='M78.895 107.294C78.895 107.294 91.3674 109.881 92.8873 109.119C94.4073 108.358 95.9292 119.472 95.9292 119.472C95.9292 119.472 96.5387 126.167 94.4092 135.143C92.2797 144.119 94.4092 148.533 94.4092 148.533C94.4092 148.533 94.1045 157.053 91.8216 161.01C89.5388 164.967 89.5407 168.617 89.5407 168.617C89.5407 168.617 85.1322 169.074 84.9769 168.008C84.9769 168.008 86.8036 163.748 86.4988 159.792C86.194 155.837 87.4112 148.228 87.4112 148.228C87.4112 148.228 85.4331 138.642 85.7379 134.999C86.0426 131.357 84.6721 123.588 83.9131 124.957C83.1541 126.326 81.1741 133.021 81.1741 134.392C81.1741 135.762 80.4074 144.888 79.9569 145.498C79.5065 146.108 79.6541 147.933 79.8055 148.845C79.9569 149.758 79.9569 156.604 79.1979 160.256C78.4389 163.909 79.0446 168.015 78.8931 168.625C78.7417 169.235 74.3293 168.776 74.1779 167.71C74.1779 167.71 73.5684 161.169 73.2636 160.256C72.9589 159.344 72.8151 147.764 72.8151 147.764C72.8151 147.764 72.5123 138.788 72.2075 137.875C71.9027 136.963 71.4408 117.335 73.4246 115.061C75.4085 112.787 74.6418 106.997 74.6418 106.997L78.895 107.294Z'
					fill='#3F3D56'
				/>
				<path
					d='M89.1477 80.7894C89.7112 81.8515 90.2786 82.9232 90.6313 84.0754C90.984 85.2276 91.1066 86.4757 90.775 87.6337C90.5378 88.357 90.2079 89.0465 89.7937 89.6851C89.4642 90.2954 89.0463 90.8535 88.5535 91.3415C87.4035 92.3922 85.7321 92.603 84.1795 92.7679C83.9132 92.8124 83.641 92.8079 83.3764 92.7545C83.1641 92.6928 82.9678 92.5855 82.8013 92.4401C82.1209 91.8649 81.8123 90.9869 81.5631 90.1395C80.9704 88.1242 80.546 86.0632 80.2942 83.9776C80.2358 83.6479 80.2358 83.3104 80.2942 82.9807C80.4284 82.4056 80.8884 81.9589 81.2219 81.4642C81.6214 80.8732 81.8496 80.1831 81.8813 79.4703C81.8947 79.1233 81.8813 78.7399 82.0998 78.4753C82.2452 78.3313 82.4215 78.2225 82.6154 78.1571C83.522 77.7736 84.4268 77.3902 85.3353 77.0221C85.8835 76.7959 86.8687 76.1939 87.4437 76.6387C87.9363 77.0221 87.6987 77.9117 87.9095 78.4447C88.2334 79.2537 88.7414 80.0225 89.1477 80.7894Z'
					fill='#FBBEBE'
				/>
				<path
					opacity={0.1}
					d='M89.1477 80.7894C89.7112 81.8515 90.2786 82.9232 90.6313 84.0754C90.984 85.2276 91.1066 86.4757 90.775 87.6337C90.5378 88.357 90.2079 89.0465 89.7937 89.6851C89.4642 90.2954 89.0463 90.8535 88.5535 91.3415C87.4035 92.3922 85.7321 92.603 84.1795 92.7679C83.9132 92.8124 83.641 92.8079 83.3764 92.7545C83.1641 92.6928 82.9678 92.5855 82.8013 92.4401C82.1209 91.8649 81.8123 90.9869 81.5631 90.1395C80.9704 88.1242 80.546 86.0632 80.2942 83.9776C80.2358 83.6479 80.2358 83.3104 80.2942 82.9807C80.4284 82.4056 80.8884 81.9589 81.2219 81.4642C81.6214 80.8732 81.8496 80.1831 81.8813 79.4703C81.8947 79.1233 81.8813 78.7399 82.0998 78.4753C82.2452 78.3313 82.4215 78.2225 82.6154 78.1571C83.522 77.7736 84.4268 77.3902 85.3353 77.0221C85.8835 76.7959 86.8687 76.1939 87.4437 76.6387C87.9363 77.0221 87.6987 77.9117 87.9095 78.4447C88.2334 79.2537 88.7414 80.0225 89.1477 80.7894Z'
					fill='black'
				/>
				<path
					d='M83.154 81.1248C80.2132 81.1248 77.8293 78.7403 77.8293 75.7988C77.8293 72.8574 80.2132 70.4729 83.154 70.4729C86.0948 70.4729 88.4788 72.8574 88.4788 75.7988C88.4788 78.7403 86.0948 81.1248 83.154 81.1248Z'
					fill='#FBBEBE'
				/>
				<path
					d='M81.2852 89.2307C82.2934 89.5931 83.3342 89.9056 84.4056 89.9439C85.2083 89.9519 86.0092 89.8664 86.792 89.6889C88.4095 89.3671 89.998 88.9138 91.5417 88.3335C91.8714 88.9834 91.9596 89.7253 91.9883 90.4539C92.0708 92.6567 91.6318 94.8423 91.4631 97.0413C91.2504 99.8366 91.4631 102.693 90.7443 105.4C90.7028 104.94 90.5239 104.503 90.2307 104.146C89.9863 103.883 89.6943 103.668 89.37 103.514C87.9194 102.764 86.2489 102.556 84.6587 102.927C83.7367 103.146 82.7572 103.554 81.8832 103.188C81.3618 102.967 80.9842 102.503 80.6948 102.016C80.2034 101.138 79.8529 100.187 79.6559 99.2001C79.0809 96.6617 79.0042 94.0371 78.9352 91.4335C78.9161 90.7376 78.617 88.669 79.194 88.159C79.5984 87.8043 79.7173 88.3105 80.0086 88.575C80.3876 88.8743 80.8212 89.097 81.2852 89.2307Z'
					fill='#F2F2F2'
				/>
				<path
					d='M88.7797 80.0875C89.1343 79.8095 89.6595 79.8958 90.0505 80.1316C90.4415 80.3674 90.7578 80.6933 91.1546 80.9081C92.2548 81.5043 93.7326 81.0672 94.7772 81.7612C95.7586 82.415 95.9273 83.7608 96.0039 84.938C96.1956 87.9288 96.3605 90.9407 97.0793 93.851C97.2709 94.6178 97.5009 95.4039 97.4281 96.1938C97.3707 96.645 97.264 97.0886 97.1099 97.5166L96.1669 100.463C95.7299 101.832 95.2871 103.222 95.2411 104.658C95.1951 106.094 95.5324 107.459 95.8679 108.83C96.5308 111.53 96.9623 114.283 97.1578 117.057C97.2173 117.975 97.2422 118.933 96.8703 119.775C96.487 120.655 95.7088 121.309 94.8884 121.805C94.068 122.302 93.1767 122.685 92.3736 123.213C90.7616 124.273 89.4678 125.943 87.5817 126.378C86.7173 126.57 85.8126 126.493 84.9424 126.324C84.9424 126.324 85.435 103.947 86.8055 100.751C88.1759 97.555 89.3912 83.711 89.3912 83.2547C89.3912 82.7984 88.7797 80.0875 88.7797 80.0875Z'
					fill='#DA4144'
				/>
				<path
					d='M81.3772 80.8198C81.3772 80.8198 74.1798 80.8198 73.1141 84.6235C72.0484 88.4272 76.156 94.6657 76.156 94.6657C76.156 94.6657 74.4826 106.989 73.7236 108.207C72.9646 109.424 73.2655 111.849 73.2655 111.849C72.7144 113.519 72.61 115.304 72.9627 117.026C73.5703 119.764 75.8531 124.633 75.8531 124.633C75.8531 124.633 78.895 124.633 79.656 126.458C80.4169 128.284 80.5683 118.546 80.5683 118.546C80.5683 118.546 83.3055 100.745 83.154 99.6811C83.0026 98.617 82.2417 89.7903 81.9369 87.3555C81.6321 84.9207 80.9689 81.7324 81.3772 80.8198Z'
					fill='#DA4144'
				/>
				<path
					d='M75.8531 83.2546C75.8531 83.2546 72.3531 84.3187 72.3531 85.5361C72.3531 86.7535 71.2817 93.2949 71.2817 95.4249C71.2817 97.5549 71.7379 97.8597 71.2817 98.6208C70.8255 99.382 76.1483 99.0771 76.1483 99.0771L77.1163 90.4057L75.8531 83.2546Z'
					fill='#DA4144'
				/>
				<path
					d='M96.5406 94.2095C96.5406 94.2095 89.694 94.6658 88.4769 93.7513C87.2597 92.8368 82.0883 94.9687 81.6321 96.1861C81.176 97.4035 88.935 97.555 90.1502 97.555C91.3654 97.555 97.1483 99.3821 97.1483 99.3821L96.5406 94.2095Z'
					fill='#A0616A'
				/>
				<path
					d='M110.634 165.082C110.634 165.082 113.826 173.134 112.168 174.093C110.51 175.051 93.0272 174.369 93.0272 174.369C93.0272 174.369 90.1156 172.567 93.4431 171.317C93.4431 171.317 96.2186 171.179 98.0203 170.069C99.8221 168.959 101.906 166.879 101.906 166.879C101.906 166.879 102.044 163.826 103.153 164.52C103.485 164.739 103.768 165.025 103.983 165.359C104.198 165.694 104.34 166.07 104.401 166.462L110.634 165.082Z'
					fill='#3F3D56'
				/>
				<path
					opacity={0.1}
					d='M110.634 165.082C110.634 165.082 113.826 173.134 112.168 174.093C110.51 175.051 93.0272 174.369 93.0272 174.369C93.0272 174.369 90.1156 172.567 93.4431 171.317C93.4431 171.317 96.2186 171.179 98.0203 170.069C99.8221 168.959 101.906 166.879 101.906 166.879C101.906 166.879 102.044 163.826 103.153 164.52C103.485 164.739 103.768 165.025 103.983 165.359C104.198 165.694 104.34 166.07 104.401 166.462L110.634 165.082Z'
					fill='black'
				/>
				<path
					d='M115.559 167.544C115.559 167.544 119.057 176.363 117.232 177.433C115.407 178.502 96.2397 177.737 96.2397 177.737C96.2397 177.737 93.0445 175.761 96.6959 174.39C96.6959 174.39 99.7378 174.239 101.716 173.021C103.694 171.804 105.975 169.516 105.975 169.516C105.975 169.516 106.126 166.169 107.343 166.93C107.708 167.17 108.018 167.483 108.253 167.85C108.489 168.217 108.645 168.629 108.712 169.06L115.559 167.544Z'
					fill='#3F3D56'
				/>
				<path
					d='M97.6044 111.554L99.7339 130.726C99.7339 130.726 100.501 139.856 102.776 141.832C102.776 141.832 102.776 144.723 103.543 145.636L104.608 157.657C104.608 157.657 103.086 162.066 103.238 162.833C103.389 163.6 102.63 166.027 102.782 166.332C102.933 166.637 110.084 166.332 110.084 166.332L108.563 141.836L103.847 107.756L97.6044 111.554Z'
					fill='#3C354C'
				/>
				<path
					opacity={0.1}
					d='M97.6044 111.554L99.7339 130.726C99.7339 130.726 100.501 139.856 102.776 141.832C102.776 141.832 102.776 144.723 103.543 145.636L104.608 157.657C104.608 157.657 103.086 162.066 103.238 162.833C103.389 163.6 102.63 166.027 102.782 166.332C102.933 166.637 110.084 166.332 110.084 166.332L108.563 141.836L103.847 107.756L97.6044 111.554Z'
					fill='black'
				/>
				<path
					d='M101.407 110.945C101.407 110.945 103.081 126.616 103.842 129.963C104.32 132.168 104.625 134.406 104.754 136.658C104.754 136.658 104.905 141.528 106.276 142.897C106.676 143.293 106.99 143.767 107.199 144.29C107.408 144.812 107.507 145.373 107.491 145.935C107.491 145.935 108.862 161.302 108.101 163.129C107.34 164.956 106.579 166.476 107.334 167.691C108.089 168.907 116.461 168.909 116.461 167.996C116.461 167.084 116.461 166.323 116.918 165.866C117.374 165.41 116.918 162.519 116.918 162.519C116.918 162.519 118.742 148.978 117.069 145.023C115.396 141.068 114.635 130.26 114.635 130.26C114.635 130.26 115.852 117.937 117.374 115.958C118.896 113.98 117.069 106.982 117.069 106.982L101.407 110.945Z'
					fill='#3C354C'
				/>
				<path
					d='M105.689 62.2789C105.513 62.6815 105.089 62.9059 104.704 63.1187C102.471 64.3495 100.602 66.1344 98.7621 67.8982C99.2214 66.3398 99.1231 64.6699 98.4842 63.1762C98.2657 62.6681 97.9763 62.1198 98.1334 61.5887C98.2503 61.2053 98.5819 60.9139 98.9001 60.6647C99.6515 60.0835 100.437 59.5476 101.252 59.06C101.827 58.7187 102.992 57.8119 103.646 57.9097C103.681 58.7935 103.698 59.5834 104.085 60.402C104.181 60.6052 105.622 62.4323 105.689 62.2789Z'
					fill='#A0616A'
				/>
				<path
					d='M97.9092 64.2344C94.4646 64.2344 91.6721 61.4413 91.6721 57.9958C91.6721 54.5504 94.4646 51.7573 97.9092 51.7573C101.354 51.7573 104.146 54.5504 104.146 57.9958C104.146 61.4413 101.354 64.2344 97.9092 64.2344Z'
					fill='#A0616A'
				/>
				<path
					d='M97.6044 72.7561C97.6044 72.7561 96.5406 67.4302 99.5825 65.605C102.624 63.7798 106.122 61.6499 106.122 61.6499C106.122 61.6499 107.188 61.4965 107.644 63.7798C107.644 63.7798 116.314 65.4535 117.228 73.6687C118.142 81.8838 116.619 93.9045 116.619 93.9045C116.619 93.9045 117.987 104.403 119.661 110.032C119.661 110.032 121.031 111.249 114.945 109.424C108.86 107.599 98.214 114.601 98.214 114.601C98.214 114.601 97.1483 113.535 97.1483 112.773C97.1483 112.012 95.9311 107.597 95.9311 107.597C95.9311 107.597 94.8673 88.8834 94.4111 86.7535C93.9549 84.6235 96.2359 73.0609 97.6044 72.7561Z'
					fill='#3F3D56'
				/>
				<path
					d='M107.035 83.2549C107.035 83.2549 98.6702 78.3852 100.344 72.1467C102.017 65.9082 105.666 63.9316 105.666 63.9316C105.666 63.9316 111.599 61.953 116.619 68.3411L116.314 71.2322C116.314 71.2322 110.838 60.126 104.905 67.1237C98.973 74.1214 107.035 83.2549 107.035 83.2549Z'
					fill='#DA4144'
				/>
				<path
					d='M116.011 68.6476V93.5959L117.565 102.106L118.292 106.073H123.609L131.061 103.333V84.9284C131.061 84.9284 124.826 72.6028 122.848 70.3213C120.87 68.0399 116.011 68.6476 116.011 68.6476Z'
					fill='#DA4144'
				/>
				<path
					opacity={0.1}
					d='M117.567 102.108L118.292 106.075H123.617L131.069 103.337L117.567 102.108Z'
					fill='black'
				/>
				<path
					d='M117.836 96.794L104.754 80.8181L116.316 97.4037L117.533 97.7066L117.836 96.794Z'
					fill='#DA4144'
				/>
				<path
					d='M99.3793 58.6401C99.0247 58.4004 98.58 58.3564 98.1545 58.3142C97.729 58.272 97.2843 58.2145 96.9451 57.9537C96.3566 57.5013 96.3125 56.6271 95.8813 56.0231C95.2583 55.147 94.0143 55.0415 92.939 55.0837C92.2816 55.1106 91.5743 55.1508 91.0223 54.7961C90.4703 54.4415 90.1981 53.7417 90.0064 53.0956C89.7783 52.3287 89.6326 51.3931 90.1981 50.8257C90.5814 50.4307 91.2025 50.3521 91.6356 50.0051C92.0688 49.6581 92.2701 49.0618 92.5135 48.5423C92.7914 47.9076 93.2309 47.3569 93.7882 46.9453C94.6047 46.3701 95.6474 46.2513 96.6422 46.2608C97.637 46.2704 98.6356 46.4027 99.6228 46.28C100.432 46.1803 101.239 45.9119 102.044 46.0308C103.071 46.1822 103.92 46.9587 104.428 47.8655C104.936 48.7724 105.151 49.8076 105.362 50.8237L105.937 53.5902C106.113 54.4491 106.291 55.3291 106.155 56.1938C105.871 57.9883 104.284 59.4146 104.175 61.2264C104.071 60.7258 103.844 60.2591 103.515 59.8679C103.185 59.4767 102.764 59.1734 102.289 58.9852C102.17 58.9271 102.035 58.9102 101.906 58.9373C101.748 58.989 101.656 59.1462 101.557 59.2785C101.296 59.6255 100.478 60.1508 100.023 59.9054C99.5902 59.685 99.9007 58.9948 99.3793 58.6401Z'
					fill='#3F3D56'
				/>
				<path
					d='M59.5761 136.05C59.5761 136.05 63.6837 136.202 64.2913 140.918C64.8989 145.634 64.2913 167.544 64.2913 167.544C64.2913 167.544 59.5761 171.042 53.7951 170.586C48.0142 170.13 38.7371 168.456 38.7371 168.456V142.766C38.7371 138.932 41.9343 135.805 45.7639 136.025C45.854 136.025 45.9556 136.037 46.038 136.046L59.5761 136.05Z'
					fill='#3C354C'
				/>
				<path
					d='M53.6437 174.085C52.1321 174.085 50.9066 172.859 50.9066 171.347C50.9066 169.835 52.1321 168.61 53.6437 168.61C55.1554 168.61 56.3809 169.835 56.3809 171.347C56.3809 172.859 55.1554 174.085 53.6437 174.085Z'
					fill='#3F3D56'
				/>
				<path
					d='M38.7371 172.868C37.2254 172.868 36 171.642 36 170.13C36 168.618 37.2254 167.392 38.7371 167.392C40.2488 167.392 41.4742 168.618 41.4742 170.13C41.4742 171.642 40.2488 172.868 38.7371 172.868Z'
					fill='#3F3D56'
				/>
				<path
					d='M115.097 87.0584C115.097 87.0584 116.162 93.7513 111.752 93.1436C111.752 93.1436 109.469 92.8388 109.164 93.1436C108.86 93.4484 97.6044 91.4699 96.0825 89.7962C96.0825 89.7962 94.2578 89.1884 93.3454 90.101C92.433 91.0136 83.3055 91.9262 86.0407 89.1865C88.7759 86.4469 93.0368 85.8391 93.0368 85.8391L98.8177 86.142C98.8177 86.142 101.86 87.0565 103.684 86.142C105.509 85.2275 110.686 85.5439 110.686 85.5439L115.097 87.0584Z'
					fill='#A0616A'
				/>
				<path
					d='M108.862 65.9099C108.862 65.9099 115.706 66.6768 115.706 76.5599V89.1864C115.706 89.1864 111.903 87.5127 111.142 87.6642C110.381 87.8156 107.34 85.6857 107.34 85.6857C107.34 85.6857 103.842 77.4724 104.146 75.191C104.451 72.9095 104.298 66.2128 108.862 65.9099Z'
					fill='#3F3D56'
				/>
				<path
					d='M77.5265 90.4038L84.3712 95.2734L80.1122 95.7297L74.1798 90.7086L77.5265 90.4038Z'
					fill='#3F3D56'
				/>
				<path
					d='M72.2017 97.7085C72.2017 97.7085 70.5284 102.885 72.8093 104.403C75.0902 105.922 78.895 99.0773 78.895 99.0773C78.895 99.0773 83.0026 94.2096 80.2636 93.1437C77.5245 92.0777 76.4071 96.7192 76.4071 96.7192L72.8151 99.8385L72.2017 97.7085Z'
					fill='#FBBEBE'
				/>
				<path
					d='M82.6212 75.7222C79.2612 75.7222 76.5374 73.9849 76.5374 71.8418C76.5374 69.6987 79.2612 67.9614 82.6212 67.9614C85.9812 67.9614 88.705 69.6987 88.705 71.8418C88.705 73.9849 85.9812 75.7222 82.6212 75.7222Z'
					fill='#3F3D56'
				/>
				<path
					d='M81.5574 66.8973C79.1216 66.8973 77.1469 64.9223 77.1469 62.4859C77.1469 60.0495 79.1216 58.0745 81.5574 58.0745C83.9932 58.0745 85.9678 60.0495 85.9678 62.4859C85.9678 64.9223 83.9932 66.8973 81.5574 66.8973Z'
					fill='#3F3D56'
				/>
				<path
					d='M97.9092 57.1598C94.6752 57.1598 92.0535 55.7976 92.0535 54.1173C92.0535 52.4369 94.6752 51.0747 97.9092 51.0747C101.143 51.0747 103.765 52.4369 103.765 54.1173C103.765 55.7976 101.143 57.1598 97.9092 57.1598Z'
					fill='#3F3D56'
				/>
				<path
					d='M103.385 59.7461C102.252 59.7461 101.333 57.4638 101.333 54.6483C101.333 51.8329 102.252 49.5505 103.385 49.5505C104.519 49.5505 105.438 51.8329 105.438 54.6483C105.438 57.4638 104.519 59.7461 103.385 59.7461Z'
					fill='#3F3D56'
				/>
			</g>
			<defs>
				<clipPath id='clipIllustration'>
					<rect
						width={102}
						height={132}
						transform='matrix(-1 0 0 1 138 46)'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const LogoSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 13' fill='none' {...props}>
			<path
				d='M23.0452 5.27989C21.3323 8.4966 17.0921 10.1003 12.7208 10.1003C9.24814 10.1003 5.83162 9.64073 2.85504 8.31841C9.96888 9.07804 16.8581 8.77794 23.0452 5.27989ZM23.0452 3.93882C20.9673 3.31986 19.0952 3.14168 17.1576 3.16043C12.2434 3.19795 7.27311 4.67969 2.88312 6.62097C6.37452 3.5168 11.5695 1.25667 16.7738 1.25667C19.2543 1.25667 22.1092 2.3164 23.0452 3.93882ZM24 4.64218C24 1.34107 19.0297 0 16.1935 0C11.7473 0 8.01258 1.62242 4.82071 3.40426C4.62414 3.0479 4.34333 2.98225 3.90339 2.92598C3.24817 2.86971 2.84568 2.84158 2.34958 2.84158C1.80668 2.84158 0.992335 2.87909 0.355833 2.98225C0.0469429 3.03852 -0.0560206 3.28235 0.0282223 3.65747C0.309032 4.67969 0.730246 5.59875 1.28251 6.46154C1.03914 7.30557 1.12338 8.10271 1.8254 8.75918C1.58204 9.74389 1.50715 10.8224 1.54459 11.7977C1.60076 12.201 1.80668 12.3135 2.14365 12.2572C3.39794 12.0603 4.61478 11.6195 5.6725 11.0568C5.93458 10.9162 6.05627 10.7567 6.13115 10.5598L9.22942 11.0381C9.70679 11.5539 10.1842 11.9946 10.7645 12.4823C11.1483 12.7636 11.5601 12.9231 12.0656 12.9231C14.3214 12.9043 16.2216 12.5667 17.7941 11.9665C18.4119 11.7696 18.8705 10.9443 19.5539 9.79078C21.0702 9.02177 24 7.57754 24 4.64218Z'
				fill='#8000ff'
			/>
		</svg>
	);
};

export const GreenTickSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 26 26' {...props}>
			<path
				d='m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z'
				fill='#32c75e'
			/>
		</svg>
	);
};

export const ConfettiSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 240 78' fill='none' {...props}>
			<path
				d='M187.81 15.3199C187.53 18.6299 187.28 21.9399 187 25.2799C187.01 25.1799 189.88 24.6999 190.16 24.6499C190.44 24.5899 193.29 24.1599 193.31 23.9599C193.59 20.6499 193.84 17.3399 194.12 13.9999C194.11 14.0999 191.24 14.5799 190.96 14.6299C190.64 14.6499 187.8 15.0899 187.81 15.3199Z'
				fill='#FFBC1F'
			/>
			<path
				d='M230.024 17.2087C228.494 18.7087 226.924 20.1787 225.394 21.6787C225.494 21.5787 228.974 24.9087 229.134 24.7487C230.664 23.2487 232.234 21.7787 233.764 20.2787C233.634 20.3687 230.184 17.0487 230.024 17.2087Z'
				fill='#3F67F2'
			/>
			<g opacity={0.92}>
				<path
					d='M49.6234 13.3127C46.5018 14.2056 44.1919 16.7674 43.6682 19.9621C43.5762 20.4678 44.749 21.988 45.0202 22.4393C45.3436 23.023 45.675 23.5746 45.9944 24.1743C46.0667 24.2947 46.4382 24.7539 46.4283 24.8964C46.9881 21.6936 49.2498 19.1198 52.3835 18.247C51.9923 18.3456 50.0868 13.198 49.6234 13.3127Z'
					fill='#30D6AF'
				/>
			</g>
			<g opacity={0.98}>
				<path
					d='M128.296 3.0055C125.91 3.53138 123.504 4.00409 121.119 4.52997C121.276 4.4937 122.629 9.81536 122.88 9.75733C125.265 9.23145 127.671 8.75874 130.057 8.23286C129.877 8.24134 128.547 2.94748 128.296 3.0055Z'
					fill='#3F67F2'
				/>
			</g>
			<g opacity={0.85}>
				<path
					d='M168.176 4.85663C167.835 6.26511 167.509 7.69247 167.17 9.11152C167.14 9.22838 170.636 10.1416 170.617 10.2117C170.958 8.80322 171.284 7.37587 171.623 5.95683C171.653 5.83996 168.177 4.91169 168.176 4.85663Z'
					fill='#FF8164'
				/>
			</g>
			<g opacity={0.7}>
				<path
					d='M236.821 10.462C236.519 8.64425 236.257 6.81311 235.955 4.99536C235.977 5.11513 231.914 5.92409 231.948 6.11572C232.25 7.93348 232.511 9.76461 232.813 11.5824C232.814 11.4464 236.854 10.6536 236.821 10.462Z'
					fill='#3F67F2'
				/>
			</g>
			<g opacity={0.85}>
				<path
					d='M94.8244 11.6956C97.9403 12.6384 101.062 13.5525 104.207 14.5014C104.113 14.4714 104.238 11.6225 104.247 11.3439C104.246 11.0633 104.415 8.24369 104.227 8.18377C101.111 7.24094 97.9894 6.32687 94.8448 5.37793C94.9386 5.40789 94.8139 8.25683 94.8048 8.53543C94.7588 8.84625 94.6019 11.6583 94.8244 11.6956Z'
					fill='#FFBC1F'
				/>
			</g>
			<g opacity={0.95}>
				<path
					d='M158.148 23.3805C158.634 24.6886 159.143 26.0036 159.636 27.3189C159.675 27.4286 162.957 26.2754 162.981 26.3412C162.494 25.0331 161.986 23.7181 161.492 22.4028C161.454 22.2931 158.178 23.4241 158.148 23.3805Z'
					fill='#FF8164'
				/>
			</g>
			<g opacity={0.97}>
				<path
					d='M205.186 13.1246C206.614 16.6317 208.073 20.1232 209.518 23.6607C209.476 23.5539 212.143 21.581 212.401 21.3853C212.655 21.1795 215.327 19.2677 215.244 19.0542C213.815 15.5471 212.356 12.0556 210.912 8.51811C210.953 8.62486 208.287 10.5978 208.028 10.7935C207.713 10.9794 205.056 12.8962 205.186 13.1246Z'
					fill='#FFBC1F'
				/>
			</g>
			<g opacity={0.79}>
				<path
					d='M82.7344 33.2801C84.5547 34.0366 86.364 34.8377 88.1843 35.5942C88.0636 35.5455 89.6327 31.4013 89.4397 31.3233C87.6194 30.5669 85.8102 29.7657 83.9899 29.0092C84.1128 29.0869 82.5414 33.2021 82.7344 33.2801Z'
					fill='#3F67F2'
				/>
			</g>
			<g opacity={0.73}>
				<path
					d='M126.107 25.8956C126.816 24.6473 127.516 23.377 128.227 22.118C128.287 22.015 125.208 20.1908 125.244 20.129C124.535 21.3772 123.835 22.6476 123.125 23.9065C123.064 24.0095 126.121 25.8429 126.107 25.8956Z'
					fill='#FF8164'
				/>
			</g>
			<g opacity={0.72}>
				<path
					d='M115.189 33.6786C114.005 33.1365 112.803 32.6045 111.61 32.0627C111.512 32.0159 110.108 34.9411 110.049 34.913C111.232 35.4551 112.435 35.9871 113.628 36.5289C113.726 36.5757 115.14 33.6697 115.189 33.6786Z'
					fill='#FF8164'
				/>
			</g>
			<path
				d='M189.373 40.025C190.203 38.0267 191.083 36.0404 191.913 34.042C191.86 34.1745 187.31 32.4519 187.225 32.6638C186.394 34.6622 185.515 36.6484 184.684 38.6468C184.77 38.5118 189.287 40.2369 189.373 40.025Z'
				fill='#3F67F2'
			/>
			<path
				d='M6.45495 17.8424C7.28544 15.8441 8.16497 13.8578 8.99547 11.8594C8.94197 11.9919 4.39242 10.2693 4.30681 10.4812C3.47631 12.4795 2.5968 14.4658 1.7663 16.4641C1.85164 16.3292 6.36933 18.0543 6.45495 17.8424Z'
				fill='#3F67F2'
			/>
			<g opacity={0.73}>
				<path
					d='M232.675 24.8066C230.705 22.2994 228.713 19.8117 226.723 17.2834C226.782 17.3601 225.081 19.5762 224.917 19.7946C224.76 20.0201 223.04 22.1879 223.157 22.3414C225.128 24.8487 227.119 27.3363 229.109 29.8647C229.051 29.7879 230.752 27.5719 230.916 27.3534C231.127 27.1299 232.833 24.9616 232.675 24.8066Z'
					fill='#FFBC1F'
				/>
			</g>
			<g opacity={0.89}>
				<path
					d='M69.8556 10.0979C69.0851 12.0014 69.4624 14.1502 70.8508 15.6552C71.067 15.898 72.2813 15.9054 72.6118 15.946C73.0287 16.0121 73.4327 16.0617 73.856 16.1361C73.9441 16.1469 74.3177 16.1406 74.3848 16.201C73.0065 14.6749 72.6044 12.5454 73.3896 10.6437C73.2864 10.8771 69.9789 9.82231 69.8556 10.0979Z'
					fill='#30D6AF'
				/>
			</g>
			<g opacity={0.86}>
				<path
					d='M141.018 43.0163C143.174 44.7641 146.1 45.1255 148.593 43.926C148.992 43.7425 149.482 42.1761 149.665 41.765C149.915 41.2524 150.139 40.75 150.402 40.2323C150.451 40.1227 150.59 39.6374 150.695 39.5746C148.178 40.7527 145.267 40.431 143.12 38.6649C143.381 38.8905 140.711 42.7481 141.018 43.0163Z'
					fill='#30D6AF'
				/>
			</g>
			<path
				d='M176.534 55.0961C177.992 57.8413 180.85 59.4943 183.946 59.3489C184.438 59.3343 185.628 57.9336 185.996 57.5899C186.478 57.1709 186.929 56.7508 187.427 56.3324C187.525 56.2407 187.881 55.8014 188.017 55.7824C184.906 55.8957 182.046 54.2901 180.605 51.5297C180.775 51.8764 176.335 54.685 176.534 55.0961Z'
				fill='#30D6AF'
			/>
			<g opacity={0.86}>
				<path
					d='M212.201 44.9369C213.918 42.0836 213.859 38.5463 212.017 35.7836C211.732 35.3399 209.789 35.0199 209.27 34.8711C208.619 34.6595 207.985 34.4775 207.326 34.2511C207.187 34.2114 206.587 34.1268 206.495 34.0129C208.315 36.8069 208.419 40.3196 206.679 43.1663C206.904 42.8187 211.934 45.3471 212.201 44.9369Z'
					fill='#30D6AF'
				/>
			</g>
			<g opacity={0.78}>
				<path
					d='M228.03 49.9352C228.69 52.3719 229.372 54.8008 230.04 57.2589C230.022 57.1848 231.947 56.096 232.133 55.9875C232.318 55.8719 234.241 54.824 234.204 54.676C233.543 52.2393 232.861 49.8104 232.193 47.3523C232.212 47.4263 230.287 48.5152 230.1 48.6236C229.877 48.7211 227.963 49.7736 228.03 49.9352Z'
					fill='#FFBC1F'
				/>
			</g>
			<g opacity={0.81}>
				<path
					d='M129.375 55.9736C128.969 54.1323 128.604 52.2751 128.199 50.4338C128.227 50.5551 124.119 51.5993 124.163 51.7933C124.569 53.6346 124.934 55.4918 125.34 57.3331C125.334 57.1941 129.42 56.1676 129.375 55.9736Z'
					fill='#3F67F2'
				/>
			</g>
			<g opacity={0.74}>
				<path
					d='M149.344 66.9754C151.783 67.6306 154.349 66.848 155.978 64.9279C156.242 64.6276 156.069 63.1443 156.069 62.7348C156.088 62.2161 156.088 61.7156 156.115 61.1878C156.115 61.0786 156.051 60.6236 156.115 60.5326C154.459 62.4436 151.92 63.2535 149.481 62.5801C149.781 62.6711 148.99 66.8662 149.344 66.9754Z'
					fill='#30D6AF'
				/>
			</g>
			<g opacity={0.81}>
				<path
					d='M211.751 58.4582C209.247 59.5191 207.588 61.8847 207.479 64.5942C207.454 65.024 208.584 66.1681 208.856 66.5156C209.184 66.9679 209.516 67.3926 209.843 67.8586C209.915 67.9513 210.271 68.2952 210.277 68.4146C210.416 65.6948 212.034 63.3242 214.55 62.2787C214.234 62.4005 212.125 58.3156 211.751 58.4582Z'
					fill='#30D6AF'
				/>
			</g>
			<g opacity={0.87}>
				<path
					d='M227.912 77.2349C229.65 75.8059 231.427 74.412 233.164 72.983C233.051 73.0784 229.715 69.2768 229.533 69.4295C227.796 70.8585 226.019 72.2524 224.281 73.6814C224.425 73.5992 227.73 77.3876 227.912 77.2349Z'
					fill='#3F67F2'
				/>
			</g>
		</svg>
	);
};

export const ConfettiMobileSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 143 64' fill='none' {...props}>
			<g opacity={0.92}>
				<path
					d='M49.6234 43.802C46.5018 44.6949 44.1919 47.2567 43.6682 50.4514C43.5762 50.9571 44.749 52.4773 45.0202 52.9286C45.3436 53.5123 45.675 54.0639 45.9944 54.6637C46.0667 54.784 46.4382 55.2433 46.4283 55.3857C46.9881 52.183 49.2498 49.6091 52.3835 48.7363C51.9923 48.8349 50.0868 43.6873 49.6234 43.802Z'
					fill='#30D6AF'
				/>
				<path
					d='M126.623 8.00513C123.502 8.898 121.192 11.4598 120.668 14.6545C120.576 15.1602 121.749 16.6804 122.02 17.1317C122.343 17.7154 122.675 18.267 122.994 18.8668C123.067 18.9871 123.438 19.4464 123.428 19.5889C123.988 16.3861 126.25 13.8123 129.383 12.9395C128.992 13.038 127.087 7.89046 126.623 8.00513Z'
					fill='#FF8F75'
				/>
			</g>
			<g opacity={0.98}>
				<path
					d='M128.296 33.4948C125.91 34.0207 123.504 34.4934 121.119 35.0193C121.276 34.983 122.629 40.3047 122.88 40.2466C125.265 39.7208 127.671 39.2481 130.057 38.7222C129.877 38.7307 128.547 33.4368 128.296 33.4948Z'
					fill='#3F67F2'
				/>
			</g>
			<g opacity={0.85}>
				<path
					d='M94.8244 42.1849C97.9403 43.1277 101.062 44.0418 104.207 44.9907C104.113 44.9608 104.238 42.1118 104.247 41.8332C104.246 41.5526 104.415 38.733 104.227 38.6731C101.111 37.7303 97.9894 36.8162 94.8448 35.8673C94.9386 35.8972 94.8139 38.7462 94.8048 39.0247C94.7588 39.3356 94.6019 42.1476 94.8244 42.1849Z'
					fill='#FFBC1F'
				/>
			</g>
			<g opacity={0.88}>
				<path
					d='M141.992 52.7464C141.416 51.8725 140.823 50.9973 140.24 50.1194C140.193 50.0458 137.989 51.4423 137.961 51.3981C138.537 52.272 139.13 53.1472 139.713 54.0251C139.76 54.0987 141.963 52.7196 141.992 52.7464Z'
					fill='#FF8164'
				/>
			</g>
			<path
				d='M6.45495 48.3317C7.28544 46.3334 8.16497 44.3471 8.99547 42.3488C8.94197 42.4812 4.39242 40.7586 4.30681 40.9705C3.47631 42.9689 2.5968 44.9551 1.7663 46.9535C1.85164 46.8185 6.36933 48.5436 6.45495 48.3317Z'
				fill='#3F67F2'
			/>
			<g opacity={0.89}>
				<path
					d='M69.8556 40.5873C69.0851 42.4907 69.4624 44.6395 70.8508 46.1446C71.067 46.3873 72.2813 46.3948 72.6118 46.4353C73.0287 46.5014 73.4327 46.551 73.856 46.6254C73.9441 46.6362 74.3177 46.6299 74.3848 46.6903C73.0065 45.1642 72.6044 43.0347 73.3896 41.133C73.2864 41.3664 69.9789 40.3116 69.8556 40.5873Z'
					fill='#30D6AF'
				/>
			</g>
		</svg>
	);
};

export const ChevronSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			fill='none'
			viewBox='0 0 12 12'
			{...props}
		>
			<path
				stroke='#444'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M3.5 11l5-5-5-5'
			></path>
		</svg>
	);
};

export const ValidityInfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M7.99984 1.33329C4.31794 1.33329 1.33317 4.31806 1.33317 7.99996C1.33317 11.6819 4.31794 14.6666 7.99984 14.6666C11.6817 14.6666 14.6665 11.6819 14.6665 7.99996C14.6665 4.31806 11.6817 1.33329 7.99984 1.33329Z'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M8 10.6666L8 7.99996'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M8 5.33337L7.99333 5.33337'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const TickSvg = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox='0 0 17 13' fill='none' {...props}>
		<path
			d='M16 1L5.6875 11.8333L1 6.90909'
			stroke='#8000ff'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const Chevron = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width='16'
		height='11'
		viewBox='0 0 20 11'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path d='M1 1L10.3333 9L19 1' stroke='#444' strokeWidth='2' />
	</svg>
);

export const ChevronWithBoundingBox = (
	props: React.SVGProps<SVGSVGElement>,
) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M1.33366 4.6665L8.00032 11.3332L14.667 4.6665'
			stroke='#666666'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
